function makeClickCheck(e: Event, classCheck: string, exact = false) {
  if (!e.target) {
    return false
  }
  const classList = (e.target as Element).classList[0]
  if (!classList) {
    return true
  }

  if (exact) {
    return classList === classCheck
  }
  return classList.includes(classCheck)
}

export default function () {
  const isCheckboxClick = (e: Event) => makeClickCheck(e, 'n-checkbox-box__border')
  const isRadioClick = (e: Event) => makeClickCheck(e, 'n-radio')
  const isCollapseArrowClick = (e: Event) => makeClickCheck(e, 'n-data-table-expand-trigger')

  return { isCheckboxClick, isRadioClick, isCollapseArrowClick }
}
